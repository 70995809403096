export enum DomainErrorsKey {
  DOMAINLOADING = 'domain-loading',
  DOMAINNOTAVAILABLEWORD = 'domain-not-available',
  AVAILABLE = 'available',
  ALREADY = 'already',
  EMPTY = 'empty',
  ALREADYGABIA = 'already-gabia',
  OVERMAX = 'over-max',
  FETCHERROR = 'fetch-error',
}
