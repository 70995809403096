
import { getGoodsInfo } from '@/api/goods';
import { getGoodsPrice } from '@/api/proxy/goods';
import { createNewOrder } from '@/api/proxy/order';
import { GoodsPriceInfo } from '@/api/goods/types';
import { ProcessedGoods, StepOneFormState } from '@/types/common/goods';
import { DomainErrorsKey } from '@/assets/data/errors/microsoft365/types';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import MicrosoftStep01Template from '@/templates/ms365/MicrosoftStep01Template.vue';
import microsoft365Error from '@/assets/data/errors/microsoft365/index.json';
import Vue from 'vue';

import {
  getUserDetail,
  isDomainAvailable,
  getUsedLicenseWithDomain,
  getUsedOldLicenseWithDomain,
} from '@/api/proxy/gapi';
import { moveToBillingPage } from '@/utils';

export default Vue.extend({
  components: {
    MicrosoftStep01Template,
    CalculatorScreen,
  },
  data() {
    return {
      formState: {
        domain: {
          value: '',
          isChecked: false,
        },
        goodsList: undefined as any,
        terms: {
          agree1: false,
          agree2: false,
          agree3: false,
        },
      } as StepOneFormState,
      errors: {
        domain: null,
      } as any,
      selectedGoodsPriceInfo: {
        totalPrice: 0 as number | undefined,
        totalPriceWithVat: 0 as number | undefined,
        vat: 0 as number | undefined,
        selectedGoods: undefined as GoodsPriceInfo[] | undefined,
        totalRulePrice: 0 as number | undefined,
        cutPrice: 0 as number | undefined,
      },
      goodsPricingList: undefined as GoodsPriceInfo[] | undefined,
      packageName: '' as string | undefined,
    };
  },
  computed: {
    goodsList(): any {
      return Object.assign([], this.formState.goodsList);
    },
    userInfo(): any {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    async goodsList(newValue) {
      const Vue = this as any;
      // this.$console.log(newValue);
      const goodsList = newValue as ProcessedGoods[];

      const goodsPriceParams = {
        ['data[order_situation]']: 'application',
      };

      let totalGoodsQuantity = 0;

      if (goodsList) {
        goodsList.forEach((goods, idx) => {
          if (goods.checked && goods.number > 0) {
            totalGoodsQuantity += goods.number;
            goodsPriceParams[`data[goods_list[${idx}][quantity]]`] = goods.number;
            goodsPriceParams[`data[goods_list[${idx}][id]]`] = goods.id;
            goodsPriceParams[`data[goods_list[${idx}][order_term]]`] = 1;
          }
        });
      }

      // 신청된 아이템 개수가 0개인 경우에 선택된 아이템을 초기화 시켜주고 종료
      if (totalGoodsQuantity === 0) {
        Vue.selectedGoodsPriceInfo = {
          totalPrice: 0,
          totalPriceWithVat: 0,
          vat: 0,
          selectedGoods: undefined,
          totalRulePrice: 0,
          cutPrice: 0,
        };
        return;
      }

      const priceData = await getGoodsPrice(goodsPriceParams);

      Vue.$console.log(priceData);

      Vue.selectedGoodsPriceInfo = {
        totalPrice: priceData?.total_pricing?.total_price,
        totalPriceWithVat: priceData?.total_pricing.total_price_with_vat,
        vat: priceData?.total_pricing.vat,
        selectedGoods: priceData?.goods_pricing_list,
        totalRulePrice: priceData?.total_pricing.total_rule_price,
        cutPrice: priceData?.total_pricing.cut_price,
      };

      Vue.$console.log(priceData, '금액 출력');
    },
  },
  async created() {
    // const termsAPI = instance.create('https://www.gabia.com/agreements');
    // this.$console.log(await termsAPI.post('/policy?policy_type=hosting'), '약관 정보');
    this.$console.log(await getUserDetail());
    this.$console.log(this.userInfo, 'step01 유저 정보');
    const data = await getGoodsInfo({
      order_situation: 'application',
      'relations[]': 'goods_composition',
      'options[only_sales_goods]': 'N',
      'package_list[0][id]': this.$store.state.goodsInfo.packageId,
    });
    this.$console.log(data, 'step-01-brain data');
    const packageInfo = data?.package_list?.[0];
    this.packageName = packageInfo?.expose_name;
    this.$console.log(this.packageName);
    const goodsList = packageInfo?.package_goods_list;
    this.$console.log(goodsList);

    this.formState.goodsList = goodsList?.map((goods) => {
      return {
        name: goods.expose_name,
        id: goods.goods_id,
        retail_price: goods.goods.retail_price,
        termUnit: goods.goods.term_unit_code.code,
        termUnitName: goods.goods.term_unit_code.code_name,
        checked: false,
        number: 1,
      };
    });
    this.$GlobalLoading.hide();
  },
  methods: {
    onNotAvailableWordInput() {
      const errorkey = DomainErrorsKey.DOMAINNOTAVAILABLEWORD;
      this.paintError(errorkey);
    },
    onAvailableWordInput() {
      const errorkey = null;
      this.paintError(errorkey);
      this.formState.domain.isChecked = false;
      this.formState = {
        ...this.formState,
        goodsList: this.formState.goodsList?.map((goods) => {
          goods.checked = false;
          return goods;
        }),
      };
    },
    async executeWithLoading(func: () => any) {
      this.$GlobalLoading.show();
      await func();
      this.$GlobalLoading.hide();
    },
    onNextStepBtnClick() {
      this.executeWithLoading(this.createOrderAndMoveToNextPage);
    },
    async createOrderAndMoveToNextPage() {
      const { domain, goodsList, terms } = this.formState;

      if (!domain.isChecked) {
        alert('도메인을 입력해야 라이선스를 신청할 수 있습니다.\n도메인을 입력해 주세요.');
        return;
      }
      if (!goodsList?.find((goods) => goods.checked && goods.number > 0)) {
        alert('신청하고 싶은 라이선스 개수를 선택해 주세요.');
        return;
      }
      if (!terms.agree1 || !terms.agree2 || !terms.agree3) {
        alert('이용 약관에 동의해야 서비스를 신청할 수 있습니다.\n이용 약관을 확인하세요.');
        return;
      }
      this.$console.log('====== 주문서 생성에 필요한 정보 =====');
      this.$console.log(this.formState.domain);
      this.$console.log(this.formState.goodsList);
      this.$console.log(this.formState.terms);
      this.$console.log('================================');

      const willOrderedServiceList = this.formState.goodsList?.reduce((prev: any, cur: any) => {
        if (cur.checked) {
          return [
            ...prev,
            {
              goods_list: [
                {
                  id: cur.id,
                  quantity: cur.number,
                  order_term: {
                    unit: cur.termUnit,
                    term: 1,
                  },
                  settlement_option: {
                    dep_item: 'MS0',
                  },
                },
              ],
            },
          ];
        } else return prev;
      }, []);

      const orderData = await createNewOrder({
        order_page: 'application',
        checkout: {
          service_list: willOrderedServiceList,
          domain_data: {
            domain: `${this.formState.domain.value}.onmicrosoft.com`,
          },
          checkout_option: {
            origin: 'gabia',
          },
          total_price: this.selectedGoodsPriceInfo.totalPriceWithVat,
        },
      });

      this.$console.log(orderData, '주문서 생성 데이타');
      if (!orderData) {
        alert('주문서 생성에 실패했습니다.\n잠시 후 다시 시도해 주십시오.');
        return;
      }
      if (
        confirm(
          'Microsoft사의 정책에 따라 Microsoft 365 서비스는 세팅 후 96시간 이내에만 환불이 가능하며, 이후에는 환불이 불가합니다. 서비스를 신청하시겠습니까?',
        )
      ) {
        moveToBillingPage({
          ordernum: orderData.order_number,
          return_url: `${process.env.VUE_APP_APPLICATION_URL}/management-information?service_id=${orderData.seqno}`,
          back_url: `${process.env.VUE_APP_APPLICATION_URL}/application?package_id=${this.$store.state.goodsInfo.packageId}`,
        });
      }
      this.$GlobalLoading.hide();
    },
    paintError(errorKey) {
      let error = microsoft365Error.domain.find((error) => {
        return error.key === errorKey;
      });
      if (error?.type === 'alert') {
        this.errors.domain = null;
        alert(error.message);
      } else {
        this.errors.domain = {
          ...error,
          message:
            errorKey === DomainErrorsKey.AVAILABLE
              ? `${this.formState.domain.value} - ${error?.message}`
              : error?.message,
        };
      }
    },
    onDomainNotCheckBtnClick() {
      let errorKey: string | null = null;

      errorKey = DomainErrorsKey.AVAILABLE;
      this.formState.domain.isChecked = true;

      this.paintError(errorKey);
    },
    async onDomainCheckBtnClick() {
      let errorKey: string | null = null;

      if (!this.formState.domain.value) {
        // 입력 X
        errorKey = DomainErrorsKey.EMPTY;
        this.paintError(errorKey);
        return;
      } else {
        // 로딩 상테
        errorKey = DomainErrorsKey.DOMAINLOADING;
        this.paintError(errorKey);
      }

      const isAvailable = (await isDomainAvailable(this.formState.domain.value))?.available;

      if (isAvailable) {
        // MS에 등록된 도메인이 아닐 경우
        errorKey = DomainErrorsKey.AVAILABLE;
        this.formState.domain.isChecked = true;
      } else if (isAvailable === undefined) {
        errorKey = DomainErrorsKey.FETCHERROR;
      } else {
        const [usedLicenses, usedOldLicenses] = await Promise.all([
          getUsedLicenseWithDomain(`${this.formState.domain.value}.onmicrosoft.com`),
          getUsedOldLicenseWithDomain(`${this.formState.domain.value}.onmicrosoft.com`),
        ]);

        this.$console.log(usedLicenses, 'used-licenses');
        this.$console.log(usedOldLicenses, 'used-old-licenses');

        if (usedLicenses && usedOldLicenses) {
          //52499, 52500, 52501, 52502, 52503, 52504 까지 가비아에서 사용중인 상품이 있는지
          let isDomainUsedAtGabia: boolean = Object.entries(usedLicenses).reduce((isDomainUsedAtGabia, [_, value]) => {
            isDomainUsedAtGabia = isDomainUsedAtGabia || value.service.usage;
            return isDomainUsedAtGabia;
          }, false);

          //52499, 52500, 52501, 52502, 52503, 52504를 제외하고 ms 구상품을 가비아에서 사용중인지
          isDomainUsedAtGabia = isDomainUsedAtGabia || usedOldLicenses.usage;

          if (isDomainUsedAtGabia) {
            // 가비아 MS로 이용 중인 도메인일 경우
            errorKey = DomainErrorsKey.ALREADYGABIA;
          } else {
            // 타사 MS로 이용 중인 도메인일 경우
            errorKey = DomainErrorsKey.ALREADY;
            this.formState.domain.isChecked = true;
          }
        } else {
          if (
            confirm(
              '현재 domain 조회 과정이 pt에 반영되지 않아 정상적인 조회가 되지 않은 것으로 판단됩니다. \n테스트를 위해 도메인이 등록 가능한 것으로 할까요?',
            )
          ) {
            errorKey = DomainErrorsKey.AVAILABLE;
            this.formState.domain.isChecked = true;
          } else {
            // TODO 임의로 내가 넣은 문구라서 수정해야 할수도
            errorKey = DomainErrorsKey.FETCHERROR;
          }
        }
      }

      this.paintError(errorKey);
    },
    onLicenseCheckBeforeDomainChecked() {
      alert('먼저 도메인을 입력하고 [확인]을 클릭해주세요.');
    },
  },
});
