
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import Vue from 'vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import MinusPlusNumberInput from '@/components/inputs/MinusPlusNumberInput.vue';
import InfoPopover from '@/components/common/InfoPopover/InfoPopover.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import MessageBox from '@/components/common/MessageBox.vue';

export default Vue.extend({
  components: {
    FormLayout,
    BillLayout,
    TableLayout,
    ColumnLayout,
    MinusPlusNumberInput,
    InfoPopover,
    InfoModal,
    MessageBox,
  },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          domain: '',
          goodsList: [],
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        };
      },
    },
    errors: {
      type: Object,
      default() {
        return {
          domain: null,
        };
      },
    },
    packageName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formTitle,
      agreeAll: false,
    };
  },
  computed: {
    appMode(): boolean {
      return process.env.VUE_APP_MODE;
    },
    //모든 것을 동의 한다는 뜻
    agreeBoth(): boolean {
      return !!(this.formState.terms.agree1 && this.formState.terms.agree2 && this.formState.terms.agree3);
    },
    packageUseTerm(): string {
      return this.packageName?.split(' ')[2]?.slice(0, -1);
    },
    domainTermsSrc(): string {
      return `${process.env.VUE_APP_POLICY_URL}/hosting/?page=application`;
    },
    microsoftTermsSrc(): string {
      return `${process.env.VUE_APP_POLICY_URL}/microsoft365/?page=application`;
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    const iframe1 = document.createElement('iframe');
    iframe1.src = this.domainTermsSrc;
    iframe1.width = '800';
    iframe1.height = '800';
    iframe1.frameBorder = '0';

    const modalContent1 = document.querySelector('#termsModal');
    modalContent1?.appendChild(iframe1);

    const iframe = document.createElement('iframe');
    iframe.src = this.microsoftTermsSrc;
    iframe.width = '800';
    iframe.height = '800';
    iframe.frameBorder = '0';

    const modalContent2 = document.querySelector('#microsoft365TermsModal');
    modalContent2?.appendChild(iframe);
  },
  methods: {
    openHostingModal() {
      this.$GabiaModal.show('terms-hosting');
    },
    openMicrosoft365Modal() {
      this.$GabiaModal.show('terms-microsoft365');
    },
    onChangeNumber(e) {
      this.$console.log(e);
      this.$emit('change', {
        ...this.formState,
        goodsList: this.formState.goodsList.map((goods) => {
          if (goods.id === e.name) {
            return {
              ...goods,
              number: e.value,
            };
          } else {
            return goods;
          }
        }),
      });
    },
    onChangeGoodsChecked(e) {
      this.$console.log(e);
      this.$emit('change', {
        ...this.formState,
        goodsList: this.formState.goodsList.map((goods) => {
          if (goods.id === e.name) {
            return {
              ...goods,
              checked: e.checked,
            };
          } else {
            return goods;
          }
        }),
      });
    },
    onGoodsCheckboxClick(e) {
      if (!this.formState.domain.isChecked) {
        this.$emit('domain-check-before-license');
      }
    },
    onClickCheckDomainBtn(e) {
      this.$emit('domain-check-btn-click');
    },
    onClickNotCheckDomainBtn(e) {
      this.$emit('domain-not-check-btn-click');
    },
    onInput(e) {
      if (/[^a-z0-9]/g.test(e.target.value)) {
        this.$emit('not-available-word-input');
      } else {
        this.$emit('available-word-input');
      }
      this.$emit('change', {
        ...this.formState,
        domain: {
          ...this.formState.domain,
          value: e.target.value.replace(/([^a-z0-9])/g, ''),
          isChecked: false,
        },
      });
    },
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
            agree2: true,
            agree3: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
            agree2: false,
            agree3: false,
          },
        });
      }
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      this.$emit('change', {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      });
    },
    onMinusPlusDeactive() {
      if (!this.formState.domain.isChecked) {
        this.$emit('domain-check-before-license');
      }
    },
  },
});
